export const API_KEY_ATTRIBUTE_NAME = 'data-aiaibot-key'
export const APP_MOUNT_POINT = 'data-aiaibot-mount-point'
export const MODE_ATTRIBUTE_NAME = 'data-aiaibot-mode'
export const MODE_LIVE = 'live'
export const INTEGRATION_ATTRIBUTE_NAME = 'data-aiaibot-integration'
export const INTEGRATION_ATTRIBUTE_VERSION_NAME =
  'data-aiaibot-integration-version'
export const INTEGRATION_HTML = 'html'
export const RELATIVE_POSITIONED_ATTRIBUTE_NAME =
  'data-aiaibot-relative-position'
export const NO_HEADER_NO_MENU_ATTRIBUTE_NAME = 'data-aiaibot-no-header-no-menu'
export const HIDDEN_HEADER_ATTRIBUTE_NAME = 'data-aiaibot-header-hidden'
export const CHATBOT_SIDE_ATTRIBUTE_NAME = 'data-aiaibot-chatbot-side'
export const COOKIELESS_ATTRIBUTE_NAME = 'data-aiaibot-no-cookies'
export const STORY_ID_ATTRIBUTE_NAME = 'data-aiaibot-story-id'
export const STORY_STATUS_ATTRIBUTE_NAME = 'data-aiaibot-story-status'
export const HIDDEN_LAUNCHER_ATTRIBUTE_NAME = 'data-aiaibot-launcher-hidden'
export const CLOSE_DISABLED_ATTRIBUTE_NAME = 'data-aiaibot-close-disabled'
export const INSTANT_LOAD = 'data-aiaibot-instant-load'
export const MESSAGE_DELAY_DISABLED_ATTRIBUTE_NAME =
  'data-aiaibot-message-delay-disabled'
export const VARIABLES_OBJECT_ATTRIBUTE_NAME =
  'data-aiaibot-variables-object-name'
export const DEFAULT_VARIABLES_OBJECT_NAME = 'aiaibotVariables'
export const DEFAULT_CONFIG_VARIABLES_OBJECT_NAME = 'aiaibotConfigVariables'
export const COMMUNICATOR_SCRIPT_ATTRIBUTE_NAME = 'data-aiaibot-communicator'
export const INTEGRATOR_CONFIG_NAME_ATTRIBUTE_NAME = 'data-integrator'
export const BOOTSTRAP_SCRIPT_NAME = 'bootstrap.js'
export const PARENT_COMMUNICATOR_SCRIPT_NAME = 'parent-communicator.js'
export const EMBED_NAME = 'embed.html'
export const INFO_NAME = 'info.json'
export const MOUNTPOINT_NAME = 'aiaibot-mountpoint'
export const FRAME_NAME = 'aiaibot-chat'
export const FRAME_INITIAL_URL = 'about:blank'
export const FRAME_URL_PARAMETER_KEY = 'key'
export const FRAME_URL_PARAMETER_TRIGGER_ID = 'trigger'
export const CHAT_CLASS = 'aiaibot-chat'
export const CHAT_ALIGN_RIGHT_CLASS = 'aiaibot-chat--align-right'
export const CHAT_ALIGN_LEFT_CLASS = 'aiaibot-chat--align-left'
export const CHAT_OPEN_CLASS = 'aiaibot-chat--open'
export const CHAT_CLOSED_CLASS = 'aiaibot-chat--closed'
export const CHAT_VISIBLE_CLASS = 'aiaibot-chat--visible'
export const CHAT_HIDDEN_CLASS = 'aiaibot-chat--hidden'
export const CHAT_FULLSCREEN_CLASS = 'aiaibot-chat--fullscreen'
export const CHAT_LAUNCHER_VISIBLE_CLASS = 'aiaibot-chat--visible-launcher'
export const CHAT_LAUNCHER_HIDDEN_CLASS = 'aiaibot-chat--hidden-launcher'
export const CHAT_CONSENT_ACCEPTED = 'consent_accepted'
export const CHAT_CONSENT_DECLINED = 'consent_rejected'
export const CHAT_SKIP_INPUT = 'skip-input'
export const CHAT_GHOST_MESSAGES = 'aiaibot-chat-ghost-messages'
export const CHAT_GHOST_MESSAGE_MOBILE = 'ghost-message-mobile'

export const CHAT_RECONNECTION_ATTEMPTS = 5
export const CHAT_LAUNCHER_SIZE = 100 // px
export const CHAT_CONTENT_WIDTH = 408 // px; 400 + 2 * $nudge
export const CHAT_CONTENT_HEIGHT = 688 // px; 500 + 60 (launcher) + 20 (space between launcher and window) + 2 * nudge
export const CHAT_CONTENT_HEIGHT_MOBILE_GHOST_MESSAGE = 360

export const CHAT_CLOSED_HEIGHT = `${CHAT_LAUNCHER_SIZE}px`
export const CHAT_CLOSED_WIDTH = `${CHAT_LAUNCHER_SIZE}px`
export const CHAT_OPENED_HEIGHT = `${CHAT_CONTENT_HEIGHT}px`
export const CHAT_OPENED_HEIGHT_GHOST_MESSAGE_MOBILE = `${CHAT_CONTENT_HEIGHT_MOBILE_GHOST_MESSAGE}px`
export const CHAT_OPENED_WIDTH = `${CHAT_CONTENT_WIDTH}px`
export const CHAT_OPENED_HIDDEN_LAUNCHER_HEIGHT = `${
  CHAT_CONTENT_HEIGHT - CHAT_LAUNCHER_SIZE
}px`
export const CHAT_OPENED_HIDDEN_LAUNCHER_WIDTH = CHAT_OPENED_WIDTH
export const CHAT_Z_INDEX = 9999

export const STREAMING_SPEED = 30 // ms

export const DELAY_SECOND = 1000
export const DELAY_BETWEEN_EACH_CHARACTER = 50 // ms
export const DELAY_BETWEEN_EACH_CHARACTER_SHORTER = 30 // ms
export const MIN_WAITING_TIME = 800 // ms
export const CURSOR_BLINK_DURATION = 1500 // ms

export const TEXT_APPEARANCE_EFFECT_ALL_AT_ONCE = 'all-at-once'
export const TEXT_APPEARANCE_EFFECT_TYPING = 'typing'
export const TEXT_APPEARANCE_EFFECT_TYPING_FASTER = 'typing-faster'

export const CHAT_ELEMENT_BUTTON_GROUP = 'button-group'
export const CHAT_ELEMENT_USER_INPUT = 'user-input'
export const CHAT_ELEMENT_BUTTON_GROUP_LINK = 'button-group-link'
export const CHAT_ELEMENT_BUTTON_LIST = 'button-list'
export const CHAT_ELEMENT_SURVEY = 'survey'
export const CHAT_ELEMENT_FILE_UPLOAD = 'file-upload'
export const CHAT_ELEMENT_DOWNLOAD = 'download'

export const CHAT_MEDIA_TYPE_IMAGE = 'image'
export const CHAT_MEDIA_TYPE_LINK = 'link'
export const CHAT_MEDIA_TYPE_VIMEO = 'vimeo'
export const CHAT_MEDIA_TYPE_YOUTUBE = 'youtube'

export const EXAMPLES = 'examples'

export const DEFAULT_READ_TIME_SECONDS = 2

export const ACTION_TYPE_NONE = 'none'
export const ACTION_TYPE_PRECANNED_MESSAGE = 'precanned-message'
export const ACTION_TYPE_FREETEXT_MESSAGE = 'freetext-message'
export const ACTION_TYPE_LIVE_CHAT_MESSAGE = 'live-chat-message'
export const ACTION_TYPE_FILE_UPLOAD = 'file-upload'

// https://developer.mozilla.org/en-US/docs/Web/API/URL
export const PATTERN_TYPE_MAPPING_TO_URL_OBJECT = {
  hostname: 'hostname',
  port: 'port',
  path: 'pathname',
  fragment: 'fragment',
  query: 'query',
}

// See https://en.wikipedia.org/wiki/Glob_(programming)
export const GLOB_PATTERN_NONE_OR_MULTIPLE_CHARACTERS = '*'
export const GLOB_PATTERN_ANY_SINGLE_CHARACTER = '?'
export const GLOB_PATTERN_SINGLE_CHARACTER_FROM_SET = '['
export const GLOB_PATTERN_MATCH_ANYTHING = '*'

// regexes
export const EMOJI_REGEX =
  '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*'
export const STORAGE_REGEX =
  /https?:\/\/.*storage.*\.aiaibot\.(?:dev|com)\/chatbot/
export const FILE_EXTENSION_REGEX = /(.[A-z0-9]+)$/
export const LARGE_EXTENSION_RE = /-large.[A-z0-9]+$/

export const COOKIE_EXPIRATION_DAYS = 3 * 365
export const USER_ID_COOKIE_NAME = 'aiaibot-uid'
export const GLOBAL_USER_ID_COOKIE_NAME = 'aiaibot-guid'
export const GLOBAL_USER_ID_COOKIE_DOMAIN = '.aiaibot.com'

// chat modifiers
export const MODIFIER_FOCUS_MODE = 'focus'
export const MODIFIER_READ_TIME = 'read-time'
export const MODIFIER_CLOSE = 'close'
export const MODIFIER_SESSION_ONCE = 'once-a-session'
export const MODIFIER_CAROUSEL = 'carousel'
export const MODIFIER_DONT_ENLARGE_IMAGE = 'dont-enlarge-image'
export const MODIFIER_USER_INTERACTION_REQUIRED = 'user-interaction-required'

// If there will me much more translations, it would make sense to read them
// from the regular locale translation files as well.
export const TRANSLATIONS = {
  de: {
    'md.warning.live_chat_started':
      'Du befindest dich gerade in einer Live Chat Konversation mit einem Agenten. Bist du sicher, dass du weiterfahren möchtest?',
  },
  en: {
    'md.warning.live_chat_started':
      'You are currently in a Live Chat conversation with an Agent. Are you sure you want to continue?',
  },
  fr: {
    'md.warning.live_chat_started':
      'Tu es actuellement dans un chat en direct avec un agent. Es-tu sûr de vouloir continuer?',
  },
  it: {
    'md.warning.live_chat_started':
      'Sei attualmente nella conversazione della Live Chat con un agente. Sei sicuro di voler continuare?',
  },
}

export const FALLBACK_LOCALE = '<%= FALLBACK_LOCALE %>'

// Webchat config defaults

export const DEFAULT_CONFIG = () => ({
  configKey: null,
  storyId: null,
  storyStatus: null,
  activeTriggerId: null,
  language: null,
  theme: {},
  behaviour: {},
  triggers: [],
  mode: MODE_LIVE,
  hosts: {
    api: null,
    chat: null,
  },
})

export const DEFAULT_INFO = () => ({
  version: '<%= VERSION %>',
  revision: '<%= REVISION %>',
})

export const DEFAULT_STATE = () => ({
  configured: false,
  loaded: false,
  visible: false,
  open: false,
  fullscreen: false,
  triggered: false,
  conversation: false,
  ready: false,
  customEventListeners: {},
})

export const DEFAULT_LISTENERS = () => ({
  ready: [],
  message: [],
  userInput: [],
  event: {
    open: [],
    close: [],
  },
})

export const DEFAULT_CONTEXT = () => ({
  features: {},
  fingerprint: {},
  urls: {
    hostUrl: null,
    chatHostUrl: null,
    apiHostUrl: null,
  },
  user: {
    uid: null,
    guid: null,
  },
  integration: {
    type: INTEGRATION_HTML,
    version: '<%= VERSION %>',
  },
  variables: {},
  configVariables: {},
})

export const MOUSE_BUTTONS = [
  'click',
  'middle-click',
  'right-click',
  'back-click',
  'forward-click',
]
