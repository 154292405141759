import {
  APP_MOUNT_POINT,
  API_KEY_ATTRIBUTE_NAME,
  COMMUNICATOR_SCRIPT_ATTRIBUTE_NAME,
  PARENT_COMMUNICATOR_SCRIPT_NAME,
  MODE_ATTRIBUTE_NAME,
  RELATIVE_POSITIONED_ATTRIBUTE_NAME,
  INTEGRATION_ATTRIBUTE_NAME,
  INTEGRATION_ATTRIBUTE_VERSION_NAME,
  INTEGRATION_HTML,
  MODE_LIVE,
  COOKIELESS_ATTRIBUTE_NAME,
  NO_HEADER_NO_MENU_ATTRIBUTE_NAME,
  HIDDEN_HEADER_ATTRIBUTE_NAME,
  HIDDEN_LAUNCHER_ATTRIBUTE_NAME,
  STORY_ID_ATTRIBUTE_NAME,
  STORY_STATUS_ATTRIBUTE_NAME,
  CLOSE_DISABLED_ATTRIBUTE_NAME,
  MESSAGE_DELAY_DISABLED_ATTRIBUTE_NAME,
  FRAME_INITIAL_URL,
  FRAME_NAME,
  DEFAULT_CONFIG,
  DEFAULT_STATE,
  DEFAULT_LISTENERS,
  DEFAULT_CONTEXT,
  DEFAULT_INFO,
  INTEGRATOR_CONFIG_NAME_ATTRIBUTE_NAME,
  CHATBOT_SIDE_ATTRIBUTE_NAME,
  CHAT_CONSENT_DECLINED,
  INSTANT_LOAD,
} from '@/common/common-constants'
import {
  createCommunicatorScript,
  createFrame,
  createMountpoint,
  createStyles,
  fetchIntegratorConfig,
} from '@/bootstrap/bootstrap-helpers'
import {
  identifyUser,
  detectBrowserFeatures,
  getResourceUrl,
  detectBrowserAndOs,
  detectUserSettings,
  getExternalVariables,
  getExternalConfigVariables,
} from '@/common/common-helpers'

// eslint-disable-next-line padding-line-between-statements
;(async (window) => {
  const DEFAULT_API_HOST = '<%= BASE_API_PATH %>'
  const DEFAULT_CHAT_HOST = '<%= BASE_CHAT_PATH %>'
  const DEFAULT_WS_HOST = '<%= BASE_WEBSOCKET_PATH %>'
  const INTEGRATOR_CONFIG_PATH = '<%= INTEGRATOR_CONFIG_PATH %>'

  try {
    if (window.aiaibot !== undefined) {
      return
    }

    const $aiaibot = (window.aiaibot = {
      config: DEFAULT_CONFIG(),
      context: DEFAULT_CONTEXT(),
      info: DEFAULT_INFO(),
      frame: null,
      bootstrapScript: null,
      listeners: DEFAULT_LISTENERS(),
      state: DEFAULT_STATE(),
    })

    const hostWindowLocalstorage = window.localStorage
    const hostWindowNavigator = window.navigator

    // =================================================================================================================
    // PUBLIC API
    // =================================================================================================================

    $aiaibot.bootstrap = async () => {
      /**
       * Phase 1
       * -------
       * Check if the script has been inserted correctly (contains a api key etc).
       * Gather parts of the configuration via the script's data- attributes as well as
       * various context (user id, fingerprint etc). These will be set on the aiaibot window object.
       */

      /**
       * Check the script has been added to the DOM correctly. This essentially checks if
       * the data-aiaibot-key attribute is present, but does not check if it is formally correct.
       */

      const bootstrapScript = document.querySelector(
        `script[${API_KEY_ATTRIBUTE_NAME}]`,
      )

      if (!bootstrapScript) {
        // eslint-disable-next-line no-console
        console.error(
          `Unable to load the aiaibot Chatbot as a 'script' element with attribute '${API_KEY_ATTRIBUTE_NAME}' could not be found. Please make sure to follow the instructions on how to embed the aiaibot Chatbot precisely.`,
        )

        return
      }

      // Start gathering configuration options
      const configKey = bootstrapScript.getAttribute(API_KEY_ATTRIBUTE_NAME)
      const mode =
        bootstrapScript.getAttribute(MODE_ATTRIBUTE_NAME) || MODE_LIVE

      if (!configKey) {
        // eslint-disable-next-line no-console
        console.error(
          `Unable to load the aiaibot Chatbot as an invalid (or no) API key has been provided. Please enter a valid API key in the '${API_KEY_ATTRIBUTE_NAME}' attribute.`,
        )

        return
      }

      $aiaibot.config.configKey = configKey
      $aiaibot.config.mode = mode

      let apiHostUrl = DEFAULT_API_HOST
      let chatHostUrl = DEFAULT_CHAT_HOST
      let wsHostUrl = DEFAULT_WS_HOST

      /**
       * Check if there are whitelabel/enterprise integrator configurations that need to be applied.
       * This can mean a variety of things, but most likely the configuration contains different host names for proxies
       * that point to chat.aiaibot, api.aiaibot.com etc.
       * See the README for more information on Whitelabel/Enterprise Integrators.
       */

      const useIntegratorConfig =
        bootstrapScript.hasAttribute(INTEGRATOR_CONFIG_NAME_ATTRIBUTE_NAME) &&
        bootstrapScript
          .getAttribute(INTEGRATOR_CONFIG_NAME_ATTRIBUTE_NAME)
          .trim() !== ''

      if (useIntegratorConfig) {
        const integratorName = bootstrapScript.getAttribute(
          INTEGRATOR_CONFIG_NAME_ATTRIBUTE_NAME,
        )

        try {
          const integratorConfig = await fetchIntegratorConfig(
            INTEGRATOR_CONFIG_PATH,
            integratorName,
          )

          if (!integratorConfig) {
            // eslint-disable-next-line no-console
            console.warn(
              'Integration configuration was empty or did not exist. Will not apply additional config.',
            )
          } else {
            // Handle proxy/whitelabel host configuration
            const { hosts = {} } = integratorConfig
            const { api = null, chat = null, ws = null } = hosts

            if (api) {
              apiHostUrl = api
            }

            if (chat) {
              chatHostUrl = chat
            }

            if (ws) {
              wsHostUrl = ws
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(
            `Error encountered fetching integrator configuration for '${integratorName}'! Will not apply additional config.`,
            error,
          )
        }
      }

      $aiaibot.config.hosts = {
        api: apiHostUrl,
        chat: chatHostUrl,
        ws: wsHostUrl,
      }

      // Handle additional configuration/theming/behaviour options that are controlled via the script tag
      if (bootstrapScript.hasAttribute(NO_HEADER_NO_MENU_ATTRIBUTE_NAME)) {
        $aiaibot.config.theme.noHeaderNoMenu = true
      }

      if (bootstrapScript.hasAttribute(HIDDEN_HEADER_ATTRIBUTE_NAME)) {
        // eslint-disable-next-line no-console
        console.warn(
          `The attribute "${HIDDEN_HEADER_ATTRIBUTE_NAME}" on your Chatbot integration script is deprecated, please remove it.`,
        )
      }

      const headerHidden =
        bootstrapScript.hasAttribute(HIDDEN_HEADER_ATTRIBUTE_NAME) &&
        bootstrapScript.getAttribute(HIDDEN_HEADER_ATTRIBUTE_NAME) !== 'false'

      // only set if true, as we dont want to override the default value
      if (headerHidden) {
        $aiaibot.config.theme.headerHidden = headerHidden
      }

      let instantLoad = false

      if (bootstrapScript.hasAttribute(INSTANT_LOAD)) {
        $aiaibot.config.instantLoad = true
        instantLoad = true
      }

      const launcherHidden =
        (bootstrapScript.hasAttribute(HIDDEN_LAUNCHER_ATTRIBUTE_NAME) &&
          bootstrapScript.getAttribute(HIDDEN_LAUNCHER_ATTRIBUTE_NAME) !==
            'false') ||
        instantLoad

      $aiaibot.config.theme.launcherHidden = launcherHidden

      const closeDisabled = bootstrapScript.hasAttribute(
        CLOSE_DISABLED_ATTRIBUTE_NAME,
      )
      const messageDelayDisabled = bootstrapScript.hasAttribute(
        MESSAGE_DELAY_DISABLED_ATTRIBUTE_NAME,
      )

      const cookieless = bootstrapScript.hasAttribute(COOKIELESS_ATTRIBUTE_NAME)

      $aiaibot.config.behaviour = {
        ...$aiaibot.config.behaviour,
        closeDisabled,
        messageDelayDisabled,
        cookieless,
      }

      $aiaibot.config.storyId =
        bootstrapScript.getAttribute(STORY_ID_ATTRIBUTE_NAME) ?? null
      $aiaibot.config.storyStatus =
        bootstrapScript.getAttribute(STORY_STATUS_ATTRIBUTE_NAME) ?? 'published'

      // Determine current integration
      const integrationType =
        bootstrapScript.getAttribute(INTEGRATION_ATTRIBUTE_NAME) ||
        INTEGRATION_HTML
      const integrationVersion =
        bootstrapScript.getAttribute(INTEGRATION_ATTRIBUTE_VERSION_NAME) ||
        '<%= VERSION %>'

      /**
       * Some pages might want to set variables to be used in Chatbot stories that origin
       * from their own systems (eg. logged in user name etc.). To do so, the host page
       * can include (or server-side render) and additional <script> block, which sets
       * the variables to be included/interpolated in the webchat. This is similar how
       * Google Analytics adds extensibility to GA include via window.dataLayer.
       *
       * The name of this window object where the variables are stored can be configured
       * via a bootstrap script parameters, so we first need to determine the name of the additional object
       * (that is different than window.aiaibot) and read the variables from there.
       */
      const variables = getExternalVariables(bootstrapScript)
      const configVariables = getExternalConfigVariables()

      const hostUrl = window.spaRoute
        ? `${window.location.origin}${window.spaRoute}`
        : window.location.href

      // Gather all the context into one structure
      $aiaibot.context = {
        ...$aiaibot.context,
        user: identifyUser(),
        urls: {
          hostUrl,
          chatHostUrl,
          apiHostUrl,
        },
        features: detectBrowserFeatures(),
        fingerprint: {
          ...detectBrowserAndOs(),
          ...detectUserSettings(),
        },
        integration: {
          type: integrationType,
          version: integrationVersion,
        },
        variables,
        configVariables,
      }

      /**
       * Phase 2:
       * -------
       * Start injecting the "runtime" environment of the chatbot. This includes
       * adding the mountpoint, adding styles for the iframe to it and adding the iframe itself
       * As a last step, the parent-communicator script is added after the bootstrap script.. This
       * will then lead to hand-over to the parent-communicator script to do more setup/fetch configuration and
       * so on.
       */

      const isBootstrapScriptInHead = document.head.contains(bootstrapScript)
      const isRelativePositioned = !!bootstrapScript.getAttribute(
        RELATIVE_POSITIONED_ATTRIBUTE_NAME,
      )

      if (bootstrapScript.hasAttribute(CHATBOT_SIDE_ATTRIBUTE_NAME)) {
        $aiaibot.config.theme.leftSideChatbot =
          bootstrapScript.getAttribute(CHATBOT_SIDE_ATTRIBUTE_NAME) === 'left'
      }

      const specifiedMountpointPlaceholder =
        bootstrapScript.getAttribute(APP_MOUNT_POINT)

      const mountpoint = createMountpoint(isRelativePositioned)
      const styles = createStyles()
      const frame = createFrame(
        FRAME_INITIAL_URL,
        FRAME_NAME,
        launcherHidden,
        $aiaibot.config.theme.leftSideChatbot,
      )

      let parentNode = specifiedMountpointPlaceholder
        ? document.getElementById(specifiedMountpointPlaceholder)
        : null

      if (specifiedMountpointPlaceholder && !parentNode) {
        parentNode = window.document.createElement('div')

        parentNode.setAttribute('id', specifiedMountpointPlaceholder)

        document.body.appendChild(parentNode)
      }

      // We have parent defined parent selector where we are going to moun the app
      if (parentNode) {
        parentNode.insertAdjacentElement('beforeend', mountpoint)
      }
      // Check if the bootstrap script was inserted in the head or within the <body>. If so, the mountpoint should be inserted before </body>
      // otherwise adjacent to the script itself as well as relative to it's parent
      else if (
        isBootstrapScriptInHead ||
        bootstrapScript.parentNode === document.body
      ) {
        document.body.insertAdjacentElement('beforeend', mountpoint)
      } else {
        bootstrapScript.parentNode.insertBefore(
          mountpoint,
          bootstrapScript.nextSibling,
        )
      }

      mountpoint.insertAdjacentElement('beforeend', styles)
      mountpoint.insertAdjacentElement('beforeend', frame)

      $aiaibot.frame = frame
      $aiaibot.bootstrapScript = bootstrapScript

      // Insert the communicator script to enable two way communication
      // This time insert it next to the initial script
      const communicatorScript = createCommunicatorScript(
        getResourceUrl(chatHostUrl, `/${PARENT_COMMUNICATOR_SCRIPT_NAME}`),
      )

      bootstrapScript.insertAdjacentElement('afterend', communicatorScript)
    }

    $aiaibot.setConsentDeclined = () => {
      hostWindowLocalstorage.setItem(CHAT_CONSENT_DECLINED, 'true')
    }

    $aiaibot.setConsentAccepted = () => {
      hostWindowLocalstorage.removeItem(CHAT_CONSENT_DECLINED)
    }

    $aiaibot.hasDeclined = () => {
      return hostWindowLocalstorage.getItem(CHAT_CONSENT_DECLINED)
    }

    $aiaibot.browserLanguage = () => {
      return hostWindowNavigator.language
    }

    $aiaibot.htmlLanguage = () => {
      return window.document.documentElement.lang
    }

    $aiaibot.teardown = () => {
      const mountpoint = document.querySelector('div#aiaibot-mountpoint')
      const communicatorScript = document.querySelector(
        `script[${COMMUNICATOR_SCRIPT_ATTRIBUTE_NAME}]`,
      )

      if (mountpoint) {
        mountpoint.parentNode.removeChild(mountpoint)
      }

      if (communicatorScript) {
        communicatorScript.parentNode.removeChild(communicatorScript)
      }

      $aiaibot.frame = null
      $aiaibot.config = DEFAULT_CONFIG()
      $aiaibot.context = DEFAULT_CONTEXT()
      $aiaibot.state = DEFAULT_STATE()
      $aiaibot.listeners = DEFAULT_LISTENERS()
    }

    $aiaibot.onReady = (handler) => {
      $aiaibot.listeners.ready.push(handler)

      // Immediately call handler if we're already ready
      if ($aiaibot.state.ready) {
        handler()
      }
    }

    // =================================================================================================================
    // MAIN
    // =================================================================================================================

    await $aiaibot.bootstrap()
  } catch (e) {
    console.error(e) // eslint-disable-line no-console
  }
})(window, window.aiaibot)
